/** @jsx jsx */
import { jsx, Box, Themed } from 'theme-ui';

import { Container } from '@sprinklr/shared-lib';
import { useContainerPadding } from '@sprinklr/shared-lib/hooks';

const TelcoTableTemplate = ({ padding }) => {
  const [pt, pb] = useContainerPadding(padding);
  return (
    <Container containerSx={{ pt, pb }}>
      <Box sx={{ overflow: 'auto' }}>
        <table
          sx={{
            width: '100%',
            minWidth: 1000,
            border: '0',
            borderCollapse: 'collapse',
            textAlign: 'left',
            fontSize: 3,
            lineHeight: 1.3,
            'td, th': {
              border: '0',
              verticalAlign: 'top',
            },
            h4: {
              border: '0',
              fontSize: 5,
              lineHeight: 1.2,
              verticalAlign: 'top',
              mb: 1,
              color: '#4987E8',
            },
            p: {
              mt: 0,
            },
            'td:last-child': {
              maxWidth: '225px',
            },
          }}
        >
          <thead>
            <tr>
              <th>
                <TableCellContent>
                  <h4 sx={{ m: 0 }}>Approach</h4>
                </TableCellContent>
              </th>
              <th>
                <TableCellContent>
                  <h4 sx={{ m: 0 }}>
                    Customer <br /> retention
                  </h4>
                </TableCellContent>
              </th>
              <th>
                <TableCellContent>
                  <h4 sx={{ m: 0 }}>
                    Operational <br /> efficiency
                  </h4>
                </TableCellContent>
              </th>
              <td>
                <TableCellContent>
                  <h4 sx={{ m: 0 }}>
                    Customer <br /> satisfaction
                  </h4>
                </TableCellContent>
              </td>
            </tr>
          </thead>
          <tbody>
            <tr>
              <td>
                <TableCellContent>
                  <strong>No solution</strong>
                  <p>
                    Natively and manually moderate and respond on each channel
                  </p>
                </TableCellContent>
              </td>
              <td>
                <TableCellContent>
                  <p>
                    Customers consider abandonment because they're frustrated
                    after raising concerns in multiple channels with no response
                  </p>
                </TableCellContent>
              </td>
              <td>
                <TableCellContent>
                  <p>
                    Many customer issues, ideas and comments are missed, or not
                    routed properly, because there is no automation or Al
                  </p>
                </TableCellContent>
              </td>
              <td>
                <TableCellContent>
                  <p>
                    No automation and unmonitored agent-customer interactions
                    leads to inconsistent and delayed responses
                  </p>
                </TableCellContent>
              </td>
            </tr>
            <tr>
              <td>
                <TableCellContent>
                  <strong>Point Solutions </strong>
                  <p>
                    Designed for managing care on social media channels only
                  </p>
                </TableCellContent>
              </td>
              <td>
                <TableCellContent>
                  <p>
                    Customer concerns raised outside of social media go
                    unanswered, leading to feelings of disappointment
                  </p>
                </TableCellContent>
              </td>
              <td>
                <TableCellContent>
                  <p>
                    Customer data is often siloed across multiple other point
                    solutions -limiting an agent’s ability to truly understand
                    the customer's history with the brand
                  </p>
                </TableCellContent>
              </td>
              <td>
                <TableCellContent>
                  <p>
                    Incomplete view into customer history and limited channel
                    coverage leads to slow and inadequate responses
                  </p>
                </TableCellContent>
              </td>
            </tr>
            <tr>
              <td>
                <TableCellContent>
                  <strong>Call Center Platforms </strong>
                  <p>
                    Limited in their ability to support customers across digital
                    channels
                  </p>
                </TableCellContent>
              </td>
              <td>
                <TableCellContent>
                  <p>
                    Customers will only stay on hold for so long before they
                    start voicing their issues online
                  </p>
                </TableCellContent>
              </td>
              <td>
                <TableCellContent>
                  <p>
                    Customers are transferred from agent to agent due to manual
                    and cumbersome call routing methods
                  </p>
                </TableCellContent>
              </td>
              <td>
                <TableCellContent>
                  <p>
                    Customer satisfaction is tracked and managed through
                    post-call feedback surveys, resulting in a reactive approach
                    to customer experience management
                  </p>
                </TableCellContent>
              </td>
            </tr>
            <tr>
              <td>
                <TableCellContent>
                  <strong>Sprinklr </strong>
                  <p>
                    Purpose-built, Al-driven digital care platform that supports
                    30+ channels (social, messaging, chat SMS, email & more)
                  </p>
                </TableCellContent>
              </td>
              <td>
                <TableCellContent>
                  <p>
                    <strong>
                      Engage with customers across all digital touchpoints
                    </strong>{' '}
                    through a single, unified platform where agents can easily
                    view, prioritize, and respond to cases within minutes
                  </p>
                </TableCellContent>
              </td>
              <td>
                <TableCellContent>
                  <p>
                    <strong>Al-powered agent workflows </strong> identify,
                    filter, & route messages that need support, and provide
                    agents with contextual historical data resulting in improved
                    SLAs and optimized workflows
                  </p>
                </TableCellContent>
              </td>
              <td>
                <TableCellContent>
                  <p>
                    <strong>Proactive customer service </strong> with
                    Al-supported Bots, Smart Responses, CSAT Prediction, and
                    skill-based routing increases productivity, improves the
                    quality of agent responses and enhances customer experiences
                  </p>
                </TableCellContent>
              </td>
            </tr>
          </tbody>
        </table>
      </Box>
    </Container>
  );
};

export default TelcoTableTemplate;

const TableCellContent = ({ children }) => (
  <Box
    sx={{
      maxWidth: '220px',
      margin: 'auto',
      pt: 3,
    }}
  >
    {children}
  </Box>
);
